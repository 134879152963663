export function CurrencyAmountFormat(amount, currency, type=null) {
  let formattedAmount = '';

  switch (currency) {
    case 'CHF':
      formattedAmount = parseFloat(amount).toLocaleString('de-CH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).replace(/,/g, "'");
      break;
    case 'EUR':
      formattedAmount = parseFloat(amount).toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      break;
    case 'GBP':
    case 'USD':
    case 'USDT':
      formattedAmount = parseFloat(amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      break;
    case 'BTC':
    case 'ETH':
      formattedAmount = parseFloat(amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 8
      }).replace(/,/g, ' ');
      break;
    default:
      throw new Error('Unsupported currency');
  }
  if(type) {
    return `${formattedAmount}`;
  }else {
    return `${currency} ${formattedAmount}`;
  }

}